import React from "react";
import { connect } from 'react-redux';
import { Link, navigate } from "gatsby"
import i18n from '../../i18n/i18n';
import { getQuestionnaire, sendAnswers } from '../../actions/DLSAction';
import { withStyles } from '@material-ui/core/styles';
import { FormGroup, FormControlLabel, Typography, RadioGroup, Radio, Button } from '@material-ui/core';
import { toast } from 'react-toastify';

const styles = theme => ({
    formContainer: {
        backgroundColor: '#D7F0CA',
        padding: '10px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    typography: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '15px',
        fontSize: '27px',
        fontWeight: '600'
    },
    radio: {
        padding: 10
    },
    buttons: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center'
    },
    button: {
        margin: 20
    }
});
class Dls extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            answers: {}
        }
    }
    componentDidMount() {
        this.props.getQuestionnaire();
    }

    render() {
        const { upitnik, classes } = this.props;
        const { answers } = this.state;
        return (
            <div className={classes.formContainer} >
                {
                    upitnik ? upitnik.map((upit, i) => {
                        return <Typography className={classes.typography}>
                            <Link to="/dls/form"
                                state={{ id_upitnik: upit.id }}>
                                {`${i + 1}. ${upit.naziv}`}
                            </Link>
                        </Typography>
                    }) :
                        <Typography className={classes.typography}>
                            There are no questionnaires! <Link to="/"> Go back </Link>
                        </Typography>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        upitnik: state.dlsReducer.upitnik
    };
}
function mapDispatchToProps(dispatch) {
    return {
        getQuestionnaire: () => dispatch(getQuestionnaire()),
        sendAnswers: (answers) => dispatch(sendAnswers(answers))
    };
}
export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(Dls));
